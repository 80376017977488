import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({title, metaDesc}) => {
    return (
        <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={metaDesc} />
            <meta name="google-site-verification" content="BW6i3Aw18f0FP7MXfWEA_GTev9hAJhyY9EVeRJicGWs" />
        </Helmet>
    )
}

export default SEO
